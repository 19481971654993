import React, { useState } from 'react';

import { Col, Container, Button, Row, Spinner } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function FarmersMarket() {
    const airtableIframe = {
        background: 'transparent',
        border: 0
    }
    const [loading, setLoading] = useState(true);

    return (
        <>
            <Helmet title={'Farmers Market and Farm Stand'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="p-md-5">
                    <h1 className="col-md-5">Farm Fresh Food</h1>
                    <p className="col-md-6">From artisanal goods to farm-fresh produce, our farmers markets have it all.</p>
                    <p>
                        <Button href="/apply/" size="lg" variant="warning text-dark me-3">Application</Button>
                        <Button href="/contact/" size="lg" variant="outline-secondary">Learn More</Button>
                    </p>
                </Container>
            </Container>

            <Container fluid className="p-5 my-5 hero-banner bg-light">
                <Row className="align-items-center py-5 justify-content-center mx-auto">
                    <Col md={4}>
                        <h2 className="fw-normal lh-1">Uptown Good Food</h2>
                        <ul className="list-unstyled">
                            <li><h3><a href="https://uptowngoodfood.com/farmers-market" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                Every Saturday from June 1st to November 23rd / 9am to 3pm<br />
                                St Nicholas Park, St Nicholas Ave St Nicholas Ave &amp;, W 137th St, New York, NY 10030</li>
                            <li><h3><a href="https://uptowngoodfood.com/csa-enrollment" target="_blank" rel="noreferrer">Farm Stand &amp; CSA</a></h3>
                                Every Thursdays from June 6th to October 31st / 4pm to 7pm<br />
                                St Nicholas Miracle Garden, 330 St Nicholas Ave, New York, NY 10027</li>
                        </ul>
                        <p>
                            <Button href="/apply/" size="lg" variant="success text-white me-3">Apply</Button>
                            <Button href="https://uptowngoodfood.com/" size="lg" variant="outline-secondary">Market Site</Button>
                        </p>
                    </Col>
                    <Col md={4}>
                        <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/markets/ugf_fm.png`} loading="lazy" alt="Uptown Good Food Farmers Market" />
                    </Col>
                </Row>
                <Row className="align-items-center py-5 justify-content-center mx-auto">
                    <Col md={4}>
                        <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/markets/bxpe.png`} loading="lazy" alt="Bronx Park East Farmers Market" />
                    </Col>
                    <Col md={4}>
                        <h2 className="fw-normal lh-1">Bronx Park East (BxPE)</h2>
                        <ul className="list-unstyled">
                            <li><h3><a href="https://bxpemarket.com" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                Sundays, June 2nd - November 24th / 9am - 4pm<br />
                                Bronx Park / Brady Playground, 2045 Bronx Pk E, Bronx, NY 10462</li>
                        </ul>
                        <p>
                            <Button href="/apply/" size="lg" variant="success text-white me-3">Apply</Button>
                            <Button href="https://bxpemarket.com/" size="lg" variant="outline-secondary">Market Site</Button>
                        </p>
                    </Col>
                </Row>
                <Row className="align-items-center py-5 justify-content-center mx-auto">
                    <Col md={6}>
                        <h2 className="fw-normal lh-1">White Plains Road</h2>
                        <ul className="list-unstyled">
                            <li><h3><a href="/farmersmarket/" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                Every Saturday from June 1st to November 23rd / 9am to 3pm<br />
                                Williamsbridge Square, White Plains Road, E211 St &amp;, E 212 St, Bronx, NY</li>
                        </ul>
                        <p>
                            <Button href="/apply/" size="lg" variant="success text-white me-3">Apply</Button>
                            <Button href="/farmersmarket/" size="lg" variant="outline-secondary">Market Site</Button>
                        </p>
                    </Col>
                    <Col md={5}>
                        <img className="img-fluid w-100" src="https://picsum.photos/id/493/500/300" loading="lazy" alt="White Plains Road Farmers Market" />
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Container fluid className="min-vh-100 p-1 my-5 bg-light text-center">
                    {loading ? <><Button variant="warning" className='my-5'><Spinner animation="grow" variant="light" as="span" role="status" size='lg'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> Loading Application... </Button></> : <></>}
                    <iframe className="airtable-embed w-100 min-vh-100" src="https://airtable.com/embed/apptWMYTZ4msRriKR/pagErmkSl3enwLByy/form" width="100%" height="100%" title="Kids Club Registration Form" style={airtableIframe} onLoad={() => setLoading(false)}></iframe>
                </Container>
            </Container>

            <Container className="my-md-5">
                <h2 className="display-3 text-center mb-5">We Accept</h2>
                <Row className="row-cols-md-2 row-cols-lg-10 g-2 g-lg-3">
                    <Col sm={12}>
                        <h3>SNAP/EBT</h3>
                        <p className="lead">In 2008, the Food Stamp Program officially changed its name to the Supplemental Nutrition Assistance Program (SNAP) and renamed the Food Stamp Act of 1977 to Food and Nutrition Act of 2008.</p>
                        <p>In August 2012, NYS officially changed the name of the Food Stamp program to the Supplemental Nutritional Assistance Program (SNAP).</p>
                        <p>The Supplemental Nutrition Assistance Program (SNAP) issues electronic benefits that can be used like cash to purchase food. SNAP helps low-income working people, senior citizens, the disabled and others feed their families.</p>
                        <p>Are you one of the 1.6 million New Yorkers who utilize SNAP (Supplemental Nutrition Assistance Program), formerly known as the Food Stamp Program? Are you looking to use SNAP EBT benefits towards farm fresh food at Community Supported Agriculture (CSA)?</p>
                        <p><Button href="benefits" size="lg" variant="outline-dark">Learn More <FontAwesomeIcon icon={faInfoCircle} /></Button>{''} <Button href="https://www.nyc.gov/site/hra/help/snap-benefits-food-program.page" size="lg" variant="warning">How to Apply <FontAwesomeIcon icon={faInfoCircle} /></Button>{''} </p>
                    </Col>
                    <Col sm={12}>
                        <h3>Farmers' Market Nutrition Program</h3>
                        <p className="lead">The FMNP was established by Congress in 1992, to provide fresh, unprepared, locally grown fruits and vegetables to Women, Infants and Children Program (WIC) participants, and to expand the awareness, use of, and sales at farmers' markets. The farmers market welcomes also seniors through the Senior Farmers' Market Nutrition Program (SFMNP) vouchers.</p>
                        <p><Button href="https://agriculture.ny.gov/consumer-benefits-farmers-markets" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''} <Button href="https://www.nyc.gov/site/dfta/about/farmers_market_nutrition_program.page" size="lg" variant="outline-dark">The Department for the Aging's <br />Farmers' Market Nutrition Program <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                    </Col>
                    <Col sm={12}>
                        <h3>Health Bucks</h3>
                        <p className="lead">Health Bucks are $2 coupons that can be used to purchase fresh fruits and vegetables at all NYC farmers markets.</p>
                        <p><Button href="https://www1.nyc.gov/site/doh/health/health-topics/health-bucks.page" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                    </Col>
                    <Col sm={12}>
                        <h3>FreshConnect</h3>
                        <p className="lead">In collaboration with the New York State Department of Agriculture and Markets, Veterans Benefits Advisors with the New York State Division of Veterans' Services are offering FreshConnect checks to Active Duty members, veterans and their immediate family members, as well as un-remarried surviving spouses of veterans.</p>
                        <p><Button href="https://veterans.ny.gov/freshconnect-checks-veterans-and-families" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                    </Col>
                    <Col sm={12}>
                        <h3>Over-The-Counter (OTC)</h3>
                        <p className="lead">OTC Network Healthy Foods Card allows health plan members to use benefit dollars to purchase eligible food products such as fruits, vegetables, eggs, milk and more.</p>
                        <p><Button href="https://www.health.ny.gov/professionals/patients/medicines/over_the_counter/" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default FarmersMarket;
