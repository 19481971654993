import { Col, Container, Nav, Row, Tab, Button } from "react-bootstrap";
import Helmet from "../components/Helmet";

function JobOpportunities() {
    return (
        <>
            <Helmet title={'Job Opportunities'}></Helmet>
            <Container className="py-5 my-5">
                <h2 className="mb-md-4">Job Opportunities</h2>
                <p>Are you craving a flexible and rewarding work experience? Launch your career with our seasonal & temporary roles! We offer flexible hours, valuable skills development, a positive team environment, and potential for permanent positions. Join our team and make this season unforgettable! </p>
                <p>
                    <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Application</Button>
                    <Button href="/about/" size="lg" variant="outline-secondary">Learn More</Button>
                </p>
            </Container>

            <Container>
                <Tab.Container id="left-tabs-roles" defaultActiveKey="farmersmarket">
                    <h2 className="mb-md-4">Roles</h2>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="underline" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="farmersmarket">Farmers Market Manager <br />(2 positions)</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="youthcoordinator">Youth Program Coordinator <br />(2 positions)</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="event">Event Coordinator <br />(2 positions)</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="intern">Internship - Various Roles</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="gardener">Internship - Gardener <br />(Harlem, NY)</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="farmersmarket">
                                    <Container fluid>
                                        <p><strong>Reports to:</strong> Executive or Operations Director</p>
                                        <p><strong>Role type:</strong> In-person</p>
                                        <p><strong>Status &amp; Pay:</strong> Part-time, non-exempt, hourly rate $18 - $20/hour (depending on experience)</p>
                                        <p><strong>Market Days:</strong> </p>
                                        <ul>
                                            <li>
                                                Thursdays, June 6 - October 31 / 3:00 PM 7:00 PM at Saint Nicholas Miracle Garden (Harlem)
                                            </li>
                                            <li>
                                                Saturday, June 1 - November 23 / 8:00 AM 5:00 PM at St Nicholas Park (Harlem)
                                            </li>
                                            <li>
                                                Saturday, June 1 - November 23 / 8:00 AM 5:00 PM at Williamsbridge Square (Williamsbridge)
                                            </li>
                                            <li>
                                                Sundays, June 2 - November 24 / 8:00 AM 5:00 PM at Bronx Park (Pelham Parkway)
                                            </li>
                                        </ul>

                                        <p><strong>Primary Function:</strong> Coordinate and manage the Saturdays and Sundays Farmers Market in the Bronx and Thursdays and Saturdays Uptown Good Food (Harlem) for the Uptown & Boogie Healthy Project, including site set-up and breakdown, sales and distribution of local farm produce/products, record-keeping, and addressing vendors' and customers' concerns and questions.</p>
                                        <p><strong>Duties:</strong></p>
                                        <ul>
                                            <li>
                                                Provide CSA shares to convey familiarity, assurance, and gratitude while promoting products.
                                            </li>
                                            <li>
                                                Set-up and breakdown of the market information tent, including setting up tables, canopies, stocking, writing, and hanging signs, and making the market site ready for sale.
                                            </li>
                                            <li>
                                                Attend weekly virtual meetings to maintain knowledge of weekly farm share, produce source, seasonal availability, and quality standards.
                                            </li>
                                            <li>
                                                Assign selling spaces if those who are not allocated on a first‐come, first‐served basis.
                                            </li>
                                            <li>
                                                Transact and record Health Bucks, SNAP, WIC, and OTC benefits on Apple iPad and distribute SNAP tokens and Health Bucks.
                                            </li>
                                            <li>
                                                Coordinate with vendors, sponsors, nonprofits, and entertainers to provide attendees with the best farmers' market experience.
                                            </li>
                                            <li>
                                                Assist with coordinating other events for Bronx Park East Farmers Market & CSA, White Plains Road Farmers Market and Uptown Good Food.
                                            </li>
                                        </ul>
                                        <p><strong>Work Location/Conditions:</strong> </p>
                                        <ul>
                                            <li>
                                                Non-market work schedules may be flexible.
                                            </li>
                                            <li>
                                                Some evening work is required.
                                            </li>
                                            <li>
                                                Work may be in different market locations and remote.
                                            </li>
                                            <li>
                                                Most work hours are outdoors in all weather conditions for up to 8-hour shifts.
                                            </li>
                                        </ul>

                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>

                                        <p><strong>In-person Start Date:</strong> </p>
                                        <ul>
                                            <li>
                                                The Bronx: Saturday, June 1, 2024<br />
                                                Harlem: Saturday, June 1, 2024
                                            </li>
                                            <li>
                                                The Bronx: Sunday, June 2, 2024
                                            </li>
                                        </ul>


                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="youthcoordinator">
                                    <Container fluid>
                                        <p><strong>Reports to:</strong> Executive or Operations Director</p>
                                        <p><strong>Role type:</strong> In-person</p>
                                        <p><strong>Status &amp; Pay:</strong> Part-time, non-exempt, hourly rate $20/hour (depending on experience)</p>
                                        <p><strong>Market Days:</strong> </p>
                                        <ul>
                                            <li>
                                                Thursdays, June 6 - October 31 / 3:00 PM 7:00 PM at Saint Nicholas Miracle Garden (Harlem)
                                            </li>
                                            <li>
                                                Saturday, June 1 - November 23 / 8:00 AM 5:00 PM at St Nicholas Park (Harlem)
                                            </li>
                                            <li>
                                                Saturday, June 1 - November 23 / 8:00 AM 5:00 PM at Williamsbridge Square (Williamsbridge)
                                            </li>
                                            <li>
                                                Sundays, June 2 - November 24 / 8:00 AM 5:00 PM at Bronx Park (Pelham Parkway)
                                            </li>
                                        </ul>

                                        <p><strong>Primary Function:</strong> We seek a passionate and creative Youth Program Coordinator to develop, organize, and lead youth programming and activities at BxPE Market in Pelham Parkway, Bronx, White Plains Road Farmers Market, Williamsbridge, Bronx, and Uptown Good Food in Harlem, Manhattan. The successful candidate will be responsible for purchasing supplies for programming, leading the Youth Crew, managing the Power of Produce (POP) Kids Club/Kids Club, and creating and conducting surveys to measure the progress and impact of the youth/kids programs.</p>
                                        <p><strong>Duties:</strong></p>
                                        <ul>
                                            <li>
                                                Develop and organize engaging and educational youth programming and activities
                                            </li>
                                            <li>
                                                Purchase supplies for youth programs and activities
                                            </li>
                                            <li>
                                                Manage the Youth Crew and the Power of Produce (POP) Kids Club/Kids Club
                                            </li>
                                            <li>
                                                Create and conduct surveys to measure the progress and impact of the youth/kids programs
                                            </li>
                                            <li>
                                                Collaborate with vendors and other organizations to promote youth engagement and education
                                            </li>
                                            <li>
                                                Foster positive relationships with program participants, vendors, and community members
                                            </li>
                                            <li>
                                                Ensure the safety and well-being of all program participants
                                            </li>
                                        </ul>
                                        <p><strong>Requirements:</strong></p>
                                        <ul>
                                            <li>
                                                Bachelor's degree in education, environmental studies, or related field preferred
                                            </li>
                                            <li>
                                                Minimum of 2 years of experience in youth programming and education
                                            </li>
                                            <li>
                                                Knowledge of and passion for agriculture and local food systems
                                            </li>
                                            <li>
                                                Ability to develop and implement engaging and educational youth programs and activities
                                            </li>
                                            <li>
                                                Strong organizational, communication, and interpersonal skills
                                            </li>
                                            <li>
                                                Ability to work collaboratively with vendors, community members, and other organizations
                                            </li>
                                            <li>
                                                Ability to work independently and meet deadlines
                                            </li>
                                            <li>
                                                Must be able to pass a background check
                                            </li>
                                        </ul>

                                        <p><strong>Work Location/Conditions:</strong> </p>
                                        <ul>
                                            <li>
                                                Non-market work schedules may be flexible.
                                            </li>
                                            <li>
                                                Some evening work is required.
                                            </li>
                                            <li>
                                                Work may be in different market locations and remote.
                                            </li>
                                            <li>
                                                Most work hours are outdoors in all weather conditions for up to 8-hour shifts.
                                            </li>
                                        </ul>

                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>

                                        <p><strong>In-person Start Date:</strong> </p>
                                        <ul>
                                            <li>
                                                The Bronx: Saturday, July 13, 2024<br />
                                                Harlem: Saturday, July 13, 2024
                                            </li>
                                            <li>
                                                The Bronx: Sunday, July 13, 2024
                                            </li>
                                        </ul>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="event">
                                    <Container fluid>
                                        <p><strong>Reports to:</strong> Executive or Operations Director</p>
                                        <p><strong>Role type:</strong> In-person</p>
                                        <p><strong>Status &amp; Pay:</strong> Part-time, non-exempt, hourly rate $20/hour (depending on experience)</p>
                                        <p><strong>Market Days:</strong> </p>
                                        <ul>
                                            <li>
                                                Thursdays, June 6 - October 31 / 3:00 PM 7:00 PM at Saint Nicholas Miracle Garden (Harlem)
                                            </li>
                                            <li>
                                                Saturday, June 1 - November 23 / 8:00 AM 5:00 PM at St Nicholas Park (Harlem)
                                            </li>
                                            <li>
                                                Saturday, June 1 - November 23 / 8:00 AM 5:00 PM at Williamsbridge Square (Williamsbridge)
                                            </li>
                                            <li>
                                                Sundays, June 2 - November 24 / 8:00 AM 5:00 PM at Bronx Park (Pelham Parkway)
                                            </li>
                                        </ul>

                                        <p>We are seeking a highly motivated Event Coordinator to oversee the successful operation of our Farmers Market and Farm Stand. In this role, you will be responsible for planning, execution, and management, ensuring a thriving marketplace that celebrates local agriculture and connects our community.</p>
                                        <p><strong>Responsibilities:</strong></p>
                                        <ul>
                                            <li>
                                                <strong>Market Management:</strong>
                                                <ul>
                                                    <li>
                                                        Coordinate vendor applications, selection, and placement with the Farmers' Market Manager.
                                                    </li>
                                                    <li>
                                                        Ensure vendors comply with all market regulations and permits.
                                                    </li>
                                                    <li>
                                                        Facilitate communication between vendors, market management, and the community.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><strong>Event Planning & Promotion:</strong>
                                                <ul>
                                                    <li>
                                                        Working with the farmers market manager, develop engaging themes and special events throughout the season.
                                                    </li>
                                                    <li>
                                                        Collaborate with local musicians, artists, and community organizations for market activations.
                                                    </li>
                                                    <li>
                                                        Manage marketing and promotional efforts to attract customers and vendors.
                                                    </li>
                                                    <li>
                                                        Coordinate media relations and press releases.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Customer Experience:</strong>
                                                <ul>
                                                    <li>
                                                        Ensure a welcoming and vibrant atmosphere for market attendees.
                                                    </li>
                                                    <li>
                                                        Develop and implement customer engagement initiatives (e.g., cooking demonstrations, product samples).
                                                    </li>
                                                    <li>
                                                        Collect and analyze customer feedback to continuously improve the market experience.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Logistics &amp; Operations: </strong>
                                                <ul>
                                                    <li>
                                                        Manage farmers market inventory (tables, tents, signage, etc.).
                                                    </li>
                                                    <li>
                                                        Coordinate logistics for set up, tear down, and waste disposal.
                                                    </li>
                                                    <li>
                                                        Maintain accurate records of vendor fees and market finances.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><strong>Community Engagement:</strong>
                                                <ul>
                                                    <li>
                                                        Attend community board meetings to represent the farmers market and gather feedback.
                                                    </li>
                                                    <li>
                                                        Collaborate with local businesses and organizations to promote the market and its vendors.
                                                    </li>
                                                    <li>
                                                        Build relationships with local farmers and producers.
                                                    </li>
                                                    <li>
                                                        Work with the Youth Coordinator to develop educational programs and activities for children at the market.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p><strong>Qualifications:</strong></p>
                                        <ul>
                                            <li>
                                                Minimum one year of experience in event coordination or a related field.
                                            </li>
                                            <li>
                                                Proven experience in vendor management and communication.
                                            </li>
                                            <li>
                                                Strong organizational and time management skills.
                                            </li>
                                            <li>
                                                Excellent communication, interpersonal, and customer service skills.
                                            </li>
                                            <li>
                                                Ability to work independently and as part of a team.
                                            </li>
                                            <li>
                                                Passion for local agriculture and community engagement.
                                            </li>
                                            <li>
                                                Proficiency in Google Workspace and Microsoft Office Suite (Word, Excel, Outlook).
                                            </li>
                                        </ul>
                                        <p><strong>Benefits:</strong></p>
                                        <ul>
                                            <li>
                                                Competitive salary (depending on experience).
                                            </li>
                                            <li>
                                                The opportunity to work in a fast-paced and rewarding environment.
                                            </li>
                                            <li>
                                                Be a part of a team passionate about supporting local farmers and producers.
                                            </li>
                                        </ul>

                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>

                                        <p><strong>In-person Start Date:</strong> </p>
                                        <ul>
                                            <li>
                                                The Bronx: Saturday, June 1, 2024<br />
                                                Harlem: Saturday, June 1, 2024
                                            </li>
                                            <li>
                                                The Bronx: Sunday, June 2, 2024
                                            </li>
                                        </ul>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="intern">
                                    <Container fluid>
                                        <p>We seek enthusiastic student interns to join our team for a hybrid role at the Harlem
                                            Community Garden. This internship offers a unique opportunity to gain hands-on
                                            experience in website development, event planning, youth engagement, content
                                            creation, and social media promotion.</p>
                                        <p><strong>Description:</strong></p>
                                        <p>We seek motivated student interns to join our dynamic team for a hybrid role at our
                                            farmers&#39; market and farm stand. This internship offers a unique opportunity to gain
                                            hands-on experience in marketing, event planning, web development, youth
                                            engagement, and community outreach.</p>
                                        <ul>
                                            <li>
                                                <strong>Website Designer:</strong>
                                                <ul>
                                                    <li>
                                                        Design a brand new website for the Harlem Community Garden.
                                                    </li>
                                                    <li>
                                                        Create a visually appealing and user-friendly online platform.
                                                    </li>
                                                    <li>
                                                        Incorporate features to showcase garden events, partners, and resources.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Website Developer and Software Engineer:</strong>
                                                <ul>
                                                    <li>
                                                        Code the website in React with Airtable API integration.
                                                    </li>
                                                    <li>
                                                        Develop functionalities to manage garden events and partners.
                                                    </li>
                                                    <li>
                                                        Ensure seamless navigation and user experience.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Youth Coordinator:</strong>
                                                <ul>
                                                    <li>
                                                        Develop a kids zone curriculum and organize events at the community garden.
                                                    </li>
                                                    <li>
                                                        Engage with young participants to promote environmental education and gardening
                                                        skills.
                                                    </li>
                                                    <li>
                                                        Create a fun and interactive environment for children to explore nature.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Social Media Coordinator:</strong>
                                                <ul>
                                                    <li>
                                                        Promote Open Garden Weekend and farm stand launch on social media platforms.
                                                    </li>
                                                    <li>
                                                        Create engaging content to increase community awareness and participation.
                                                    </li>
                                                    <li>
                                                        Monitor and respond to audience feedback and inquiries.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Farmers Market Assistant (3 positions):</strong>
                                            </li>
                                            <ul>
                                                <li>
                                                    Manage and take the lead for weekend farmers markets.
                                                </li>
                                                <li>
                                                    Assist vendors, set up stalls, and coordinate activities.
                                                </li>
                                                <li>
                                                    Provide excellent customer service and ensure a positive experience for visitors.
                                                </li>
                                            </ul>
                                        </ul>
                                        <p>
                                            <strong>Requirements:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                Currently enrolled in bootcamp, college or university.
                                            </li>
                                            <li>
                                                Passion for community engagement, urban agriculture, and environmental
                                                sustainability.
                                            </li>
                                            <li>
                                                Strong communication, organizational, and problem-solving skills.
                                            </li>
                                            <li>
                                                Ability to work independently and collaboratively in a team environment.
                                            </li>
                                            <li>
                                                Availability to work in-person on Saturdays in June, with flexibility for additional hours
                                                and events.
                                            </li>
                                        </ul>

                                        <p>
                                            <strong>Benefits:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                Hands-on experience in website development, event planning, youth engagement,
                                                content creation, and social media promotion.
                                            </li>
                                            <li>
                                                Mentorship and guidance from experienced professionals in urban agriculture and
                                                community development.
                                            </li>
                                            <li>
                                                Opportunity to positively impact local communities and contribute to the growth of the
                                                Harlem Community Garden.
                                            </li>
                                            <li>
                                                Potential for academic credit and professional development opportunities.
                                            </li>
                                        </ul>

                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="gardener">
                                    <Container fluid>
                                        <p>We're seeking a dedicated and experienced seasonal gardener to join our team at a well-established (3+ years) vegetable and herb garden in Harlem, NY.</p>
                                        <p><strong>Reports to:</strong> Garden Manager, Executive or Operations Director</p>
                                        <p><strong>Role type:</strong> In-person</p>
                                        <p><strong>Days:</strong> </p>
                                        <ul>
                                            <li>
                                                Monday - Friday, May 13 - October 31 / 8:00 AM 5:00 PM at Harlem, NY
                                            </li>
                                            <li>
                                                Thursdays, June 6 - October 31 / 3:00 PM 7:00 PM at Saint Nicholas Miracle Garden (Harlem)
                                            </li>
                                        </ul>

                                        <p><strong>Our Garden:</strong> </p>
                                        <ul>
                                            <li>
                                                The garden promotes environmental awareness and serves as a growing educational resource and community gardening space. In addition, it addresses food insecurity by providing food access programming and workshops on healthy eating and sustainable lifestyles.
                                            </li>
                                            <li>
                                                Supplies fresh produce to farm stands in Harlem and a CSA program (Harlem &amp; Bronx) during peak season (June-October).
                                            </li>
                                        </ul>

                                        <p><strong>Job Responsibilities:</strong></p>
                                        <ul>
                                            <li>Planting &amp; Propagation: Seed and propagate seedlings in the greenhouse.</li>
                                            <li>Fieldwork: Prepare land, transplant seedlings, and provide essential plant care (irrigation, weeding, pruning, trellising, pest management).</li>
                                            <li>Harvesting &amp; Packing: Harvest, wash, and pack vegetables, herbs, and fruit.</li>
                                            <li>CSA Deliveries: Occasionally fill in as a delivery driver for the CSA program in Manhattan and The Bronx.</li>
                                            <li>Garden Maintenance: Keep all farm areas neat, clean, and organized.</li>
                                        </ul>

                                        <p><strong>The Ideal Candidate:</strong></p>
                                        <ul>
                                            <li>1-5 years of experience working on a small-scale vegetable farm using regenerative and organic practices.</li>
                                            <li>Strong work ethic, self-driven, and able to work independently and collaboratively.</li>
                                            <li>Excellent communication and interpersonal skills.</li>
                                            <li>Physically able to perform physically demanding tasks outdoors in all weather conditions.</li>
                                            <li>Some weekend availability is required.</li>
                                        </ul>
                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>

                                        <p><strong>In-person Start Date:</strong> </p>
                                        <ul>
                                            <li>
                                                Harlem, New York: Monday, May 13, 2024
                                            </li>
                                        </ul>
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    );
}

export default JobOpportunities;
